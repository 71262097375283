@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .max-w-px-120 {
        max-width: 120px;
    }
    
    .max-w-px-140 {
        max-width: 140px;
    }
    
    .h-500 {
        height: 500px
    }
    .h-400{
        height:400px
    }
    
    .h-88vh{
        height:88vh
    }
}

/* overriding classess */

.leaflet-container {
    height: 30vh;
    border-radius: 5px;
    margin-top: 10px;
    z-index: 0;
}

.tab.tab-active {
    color: #dc3a4e !important
}

.css-1pahdxg-control {
    box-shadow: none !important;
    border-color: #dc3a4e !important;
}

[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty,
    /*!*/
    /*!*/
    );
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-offset-shadow: none !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
    border-color: #dc3a4e;
}

#notifications-scroll::-webkit-scrollbar {
    display: none;
    /* hide the scroll bar in posters */
}


/* radion button css */
input[type="radio"] {
    
    
    
    display: inline-block;
    width: 20px;
    height: 20px;
    padding: 3px;
    
    background-clip: content-box;
    border: 2px solid #bbbbbb;
    background-color: #e7e6e7;
    border-radius: 50%;
    
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

/* appearance for checked radiobutton * */
input[type="radio"]:checked {
    background-image: none !important;
    background-color: #F44336;
    border: 2px solid #F44336;
}

input[type="radio"]:checked:hover {
    background-color: #F44336;
    border: 2px solid #F44336;
}

input[type="radio"]:checked:active {
    background-color: #F44336;
    border: 2px solid #F44336;
}


input[type="radio"]:checked:focus {
    background-color: #F44336;
    border: 2px solid #F44336;
}

input[type='radio']:focus {
    outline: 0px !important;
    outline-offset: unset !important;
    outline-style: unset !important;
    box-shadow: none !important;
    outline-color: unset !important;
}

:root {
    /* background-color: hsla(var(--b1)/var(--tw-bg-opacity, 1)); */
    background-color: #F4F5F7;
    color: black;
    --animation-curve: cubic-bezier(0.12, 0.7, 0.74, 0.71);   
}

.fa-rotate-45 {
    -ms-transform: rotate(-45deg);
    /* Internet Explorer 9 */
    -webkit-transform: rotate(-45deg);
    /* Chrome, Safari, Opera */
    transform: rotate(-45deg);
    /* Standard syntax */
}

.animate-call {
    animation: callScaling 1.7s cubic-bezier(0, 0, 0.2, 1) infinite;
    
    
}


@keyframes callScaling {
    
    75%,
    100% {
        transform: scale(2);
        opacity: 0;
    }
}